/*!
 * @name videojs-hls-source-selector
 * @version 0.0.7
 * @author Justin Fujita <justin@pivotshare.com>
 * @license MIT
 */
(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.videojsHlsSourceSelector = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
(function (global){
'use strict';

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

function _interopDefault(ex) {
  return ex && (typeof ex === 'undefined' ? 'undefined' : _typeof(ex)) === 'object' && 'default' in ex ? ex['default'] : ex;
}

var videojs = _interopDefault((typeof window !== "undefined" ? window['videojs'] : typeof global !== "undefined" ? global['videojs'] : null));

// Default options for the plugin.
var defaults = {};

// Cross-compatibility for Video.js 5 and 6.
var registerPlugin = videojs.registerPlugin || videojs.plugin;
// const dom = videojs.dom || videojs;

/**
 * Function to invoke when the player is ready.
 *
 * This is a great place for your plugin to initialize itself. When this
 * function is called, the player will have its DOM and child components
 * in place.
 *
 * @function onPlayerReady
 * @param    {Player} player
 *           A Video.js player.
 * @param    {Object} [options={}]
 *           An object of options left to the plugin author to define.
 */
var onPlayerReady = function onPlayerReady(player, options) {
  player.addClass('vjs-hls-source-selector');
  console.log("videojs-hls-source-selector initialized!");

  var qualityLevels = player.qualityLevels();
  console.log("player.techName_:" + player.techName_);
  //This plugin only supports level selection for HLS playback
  if (player.techName_ != 'Html5') {
    return false;
  }

  player.on(['loadedmetadata'], function (e) {
    console.warn('VIDEOJS loadedmetadata event');

    // hack for plugin idempodency... prevents duplicate menubuttons from being inserted into the player if multiple player.hlsSourceSelector() functions called.
    if (player.videojs_hls_source_selector_initialized == 'undefined' || player.videojs_hls_source_selector_initialized == true) {
      console.log("player.videojs_hls_source_selector_initialized == true");
      return;
    } else {
      console.log("player.videojs_hls_source_selector_initialized == false");
      player.videojs_hls_source_selector_initialized = true;
    }

    if (options && options.default) {
      console.log("options.default: " + options.default);
      console.log("player.qualityLevels(): ");
      console.log(player.qualityLevels());
      console.log("player.qualityLevels().length: ");
      console.log(player.qualityLevels().length);
      if (options.default == 'low') {
        for (var i = 0; i < player.qualityLevels().length; i++) {
          if (i == 0) {
            player.qualityLevels()[i].enabled = true;
          } else {
            player.qualityLevels()[i].enabled = false;
          }
        }
      } else if (options.default = 'high') {
        for (var i = 0; i < player.qualityLevels().length; i++) {
          if (i == player.qualityLevels().length - 1) {
            player.qualityLevels()[i].enabled = true;
          } else {
            player.qualityLevels()[i].enabled = false;
          }
        }
      }
    }

    //Menu bar button 
    var MenuButton = videojs.getComponent('MenuButton');
    var ResolutionButton = videojs.extend(MenuButton, {
      constructor: function constructor() {
        this.label = document.createElement('span');
        options.label = 'Quality';
        MenuButton.apply(this, arguments);
        this.el().setAttribute('aria-label', 'Quality');
        this.controlText('Quality');

        var staticLabel = document.createElement('span');
        videojs.addClass(staticLabel, 'vjs-menu-icon');
        this.el().appendChild(staticLabel);
        qualityLevels.on(['change', 'addqualitylevel'], videojs.bind(this, this.update));
      } /*,
        handleClick: function() {
        console.log("Button clicked!");
        }*/
    });

    ResolutionButton.prototype.update = function () {
      return MenuButton.prototype.update.call(this);
    };

    //Populate menu with resolution/bitrate options
    ResolutionButton.prototype.createItems = function () {
      var menuItems = [];
      var labels = player.qualityLevels || {};

      for (var i = 0; i < player.qualityLevels().length; i++) {
        var j = player.qualityLevels().length - (i + 1);

        console.log(player.qualityLevels()[j].id);
        console.log(player.qualityLevels()[j]);
        var label = "" + j;
        //Display height if height metadata is provided with the stream, else use bitrate
        if (player.qualityLevels()[j].height) {
          label = player.qualityLevels()[j].height;
        } else if (player.qualityLevels()[j].bitrate) {
          label = Math.floor(player.qualityLevels()[j].bitrate / 1e3) + ' kbps';
        }

        menuItems.push(new ResolutionMenuItem(this.player_, {
          label: label,
          index: j,
          selected: j === (player.qualityLevels.selectedIndex ? label : false)
        }));
      }
      //If there are more than one quality levels, offer an 'auto' option
      if (player.qualityLevels().length > 1) {
        menuItems.push(new ResolutionMenuItem(this.player_, {
          label: 'Auto',
          index: player.qualityLevels().length,
          selected: false
        }));
      }
      return menuItems;
    };

    ResolutionButton.prototype.buildCSSClass = function () {
      return MenuButton.prototype.buildCSSClass.call(this) + 'vjs-hls-source-selector-button';
    };
    videojs.registerComponent('ResolutionButton', ResolutionButton);
    player.getChild('controlBar').addChild('ResolutionButton', {});
  });

  //Resolution/bitrate menu items
  var MenuItem = videojs.getComponent('MenuItem');
  var ResolutionMenuItem = videojs.extend(MenuItem, {
    constructor: function constructor(player, options) {
      options.selectable = true;
      // Sets this.player_, this.options_ and initializes the component
      MenuItem.call(this, player, options);
      //this.selected = options.selected;

      qualityLevels.on('change', videojs.bind(this, this.update));
      player.on('resolutionchange', videojs.bind(this, this.update));
    }
  });

  //Resolution/bitrate menu item click/update handler functions
  ResolutionMenuItem.prototype.handleClick = function (event) {
    MenuItem.prototype.handleClick.call(this, event);
    //this.player_.currentResolution(this.options_.label);
    console.log("Change quality to " + this.options_.label);
    console.log("qualityLevels: ");
    console.log(this.player_.qualityLevels());
    this.selected_ = true;
    this.selected(true);
    for (var i = 0; i < this.player_.qualityLevels().length; i++) {
      //If this is the the Auto option, enable all renditions for adaptive selection
      if (this.options_.index == this.player_.qualityLevels().length) {
        this.player_.qualityLevels()[i].enabled = true;
      } else if (i == this.options_.index) {
        this.player_.qualityLevels()[i].enabled = true;
      } else {
        this.player_.qualityLevels()[i].enabled = false;
      }
    }
  };
  ResolutionMenuItem.prototype.update = function () {
    var selection = this.player_.qualityLevels().selectedIndex;
    console.log(this.options_.index + " == " + selection);
    console.log("menuItem.index === qualityLevels().selectedIndex");
    this.selected(this.options_.index == selection);
    this.selected_ = this.options_.index === selection;
  };
  MenuItem.registerComponent('ResolutionMenuItem', ResolutionMenuItem);
};

/**
 * A video.js plugin.
 *
 * In the plugin function, the value of `this` is a video.js `Player`
 * instance. You cannot rely on the player being in a "ready" state here,
 * depending on how the plugin is invoked. This may or may not be important
 * to you; if not, remove the wait for "ready"!
 *
 * @function hlsSourceSelector
 * @param    {Object} [options={}]
 *           An object of options left to the plugin author to define.
 */
var hlsSourceSelector = function hlsSourceSelector(options) {
  var _this = this;

  this.ready(function () {
    onPlayerReady(_this, videojs.mergeOptions(defaults, options));
  });
};

// Register the plugin with video.js.
registerPlugin('hlsSourceSelector', hlsSourceSelector);

// Include the version number.
hlsSourceSelector.VERSION = '0.0.7';

module.exports = hlsSourceSelector;

}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})

},{}]},{},[1])(1)
});
